<template>
  <b-row v-if="loading">
    <b-col>
      <b-spinner></b-spinner>
    </b-col>
  </b-row>
  <b-row v-else>
    <b-container class="mt-4">
      <div class="row">
        <div class="col-12 d-flex flex-row">
          <BackButton class="mr-2"></BackButton>
          <h4 class="m-0">
            Member invitations for client
            <b-link :to="`/onboarding/client/edit/${client.id}`">{{ client.display_name }}</b-link>
          </h4>
          <div class="ml-auto d-flex align-self-end">
            <b-button class="ml-2" variant="secondary"
                      :to="`/onboarding/clients/${client.id}/invitations/new`">
              New Page
            </b-button>
          </div>
        </div>
        <div class="col-12">
          <hr>
        </div>
      </div>
      <div class="p-3">
        <div class="row mt-3">
          <div class="col-12">
            <table class="table">
              <thead class="thead-light">
              <tr>
                <th class="text-center" scope="col">Page Title</th>
                <th class="text-center" scope="col">URL</th>
                <th class="text-center" scope="col">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="page in pages" :key="page.id" :class="[page.deleted_at ? 'row-deleted' : '']">
                <td v-if="!page.deleted_at" class="align-middle text-center">{{ page.page_title }}</td>
                <td v-else class="align-middle text-center">(deleted) {{ page.page_title }}</td>
                <td class="col-md-7 align-middle text-center">
                  {{ inviteUrl(page.page_uuid) }}
                  <b-button variant="light"
                            :disabled="!!page.deleted_at"
                            @click="copyUrl(inviteUrl(page.page_uuid))">
                    Copy URL
                  </b-button>
                </td>
                <td class="align-middle text-center">
                  <b-button v-b-tooltip.hover
                            title="Edit page"
                            size="sm"
                            :disabled="!!page.deleted_at"
                            variant="light"
                            class="client-entry-action mr-2"
                            @click="editPage(page)">
                    <feather type="edit-2"></feather>
                  </b-button>
                  <b-button v-if="page.deleted_at"
                            variant="info"
                            v-b-tooltip.hover title="Reactivate page"
                            @click="reactivatePage(page)">
                    <feather type="refresh-ccw"></feather>
                  </b-button>
                  <b-button v-if="!page.deleted_at" v-b-modal="`modal-delete-page-${page.id}`"
                            size="sm" variant="danger"
                            v-b-tooltip.hover title="Delete page">
                    <feather type="trash"></feather>
                  </b-button>
                  <b-modal :id="`modal-delete-page-${page.id}`"
                           ok-title="Delete"
                           size="lg"
                           @ok="deletePage(page)"
                           :title="`Are you sure you want to delete ${page.title} invitation page?`">
                    <div class="d-flex flex-column">
                      Are you sure you want to delete this page? Some of our institutions might use this link. Make
                      sure to
                      let them know how to invite members to their clients form now on.
                    </div>
                  </b-modal>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <infinite-loading class="w-100" ref="infiniteLoader" @infinite="infiniteHandler">
            <template v-slot:no-results>
              <div class="d-flex justify-content-center flex-row align-items-center">
                <feather class="no-results-icon" type="meh"></feather>
                <h5 style="margin-left: 10px;">No member invitations pages</h5>
              </div>
            </template>
            <template v-slot:no-more>&nbsp;</template>
            <template v-slot:error>
              <h5>:( Could not fetch member invitations pages, please check your internet connection and refresh</h5>
              <br>
            </template>
          </infinite-loading>
        </div>
      </div>
    </b-container>
  </b-row>
</template>

<script>
import moment from 'moment-timezone';
import InfiniteLoading from 'vue-infinite-loading';
import BackButton from '../../../components/BackButton.vue';
import copyToClipboard from '../../../scripts/tools/accessibility';

export default {
  name: 'MemberInvitations',
  components: {
    InfiniteLoading,
    BackButton,
  },
  data() {
    return {
      loading: false,
      client: null,
      pages: [],
      total: 0,
      page: 1,
      limit: 20,
    };
  },
  async beforeMount() {
    this.loading = true;
    const clients = await this.$store.dispatch('Core/fetchClients');
    this.client = clients.find(c => Number(c.id) === Number(this.$route.params.clientId));
    this.loading = false;
  },
  methods: {
    copyUrl(url) {
      copyToClipboard(url);
      this.$noty.success('Link copied to clipboard!');
    },
    editPage(page) {
      this.$router.push({
        name: 'EditClientMemberInvitations',
        params: {
          clientId: this.client.id,
          uuid: page.page_uuid,
        },
      });
    },
    inviteUrl(pageUuid) {
      const baseUrl = this.$store.getters['Onboarding/getMemberInvitationUrl'].replace(/\/$/, '');
      return `${baseUrl}/c/${this.client.reference}/${pageUuid}`;
    },
    async deletePage(page) {
      const result = await this.$store.dispatch('Onboarding/deleteMemberInvitationPage', {
        clientRef: this.client.reference,
        uuid: page.page_uuid,
      });
      if (result) {
        this.$noty.success('Invitation page has been deleted!');
        page.deleted_at = moment().tz('UTC').toISOString();
      }
    },
    async reactivatePage(page) {
      const result = await this.$store.dispatch('Onboarding/restoreMemberInvitationPage', {
        clientRef: this.client.reference,
        uuid: page.page_uuid,
      });
      if (result) {
        this.$noty.success('Invitation page has been restored!');
        page.deleted_at = null;
      }
    },
    async infiniteHandler($state) {
      const offset = (this.page - 1) * this.limit;

      const result = await this.$store.dispatch('Onboarding/fetchMemberInvitations', {
        clientRef: this.client.reference,
        params: {
          limit: this.limit,
          offset,
        },
      });
      if (result !== null) {
        this.pages = this.pages.concat(result.list);
        this.total = result.total;
        this.page += 1;

        if (result.list.length) {
          $state.loaded();
          return;
        }

        $state.complete();
      }
    },
  },
};
</script>

<style lang="scss">
.row-deleted {
  color: grey
}
</style>
